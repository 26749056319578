<template>
  <div style="padding-bottom: 20px">
    <div style="margin: 10px 0">
      <div style="width: 100%; display: flex">
        <div style="width: 100%">

          <div class="company-intro">
            <el-card style="font-size: 20px; line-height: 40px; color: #666; padding: 20px 100px">
              <div>欢迎来到我们的宠物天地</div>
              <div>公司介绍：</div>
              <div>我们是一家致力于为您提供优质宠物产品和服务的宠物商店公司。在这里，我们热爱动物，关注它们的幸福和健康。</div>
              <p>感谢您选择我们，让我们一起为您的宠物创造更美好的生活！🐶🐱</p>
            </el-card>

          </div>

        </div>
      </div>
    </div>

    <div style="margin: 10px 0; padding: 20px; background-color: #fff; border-radius: 10px" class="company-push">
      <div style="padding: 10px 0; font-size: 28px; color: #67C23A">首页推送</div>
      <el-collapse v-model="active">
        <el-collapse-item :name="index + ''" v-for="(item, index) in hostPush" :key="index">
          <template slot="title">
            <span style="font-size: 18px; color: #666">{{ item.name }}</span>
          </template>

          <mavon-editor
              class="md"
              :value="item.content"
              :subfield="false"
              :defaultOpen="'preview'"
              :toolbarsFlag="false"
              :editable="false"
              :scrollStyle="true"
              :ishljs="true"
          />
        </el-collapse-item>
      </el-collapse>
    </div>

  </div>
</template>


<script>
export default {
  name: "FrontHome",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      hostPush: [],
      active: '0'
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/hotPush/front").then(res => {
        this.hostPush = res.data
      })
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
  }
}
</script>

<style>

</style>
